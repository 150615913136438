import * as React from "react"

const Bay42LogoGold = (props) => (
  <svg width={71} height={63} xmlns="http://www.w3.org/2000/svg" {...props} style={{transform: "scale(.8)"}}>
    <title>{"Bay42 Logo"}</title>
    <g fill="none" fillRule="evenodd">
      <g fill="#C8A962">
        <path d="M56.856 39.112 42.56 60.572c1.682-.722 3.622-1.378 6.08-1.378 3.105 0 5.433 1.05 7.503 1.968 1.811.788 3.364 1.51 5.433 1.51 2.07 0 3.623-.722 5.498-1.51 1.035-.459 2.2-.984 3.428-1.312V54.6H57.955l8.409-12.01c6.21-9.712.97-21.787-10.996-21.459-7.374.197-13.454 4.594-13.325 13.781H51.1c0-3.28 1.682-4.2 4.075-4.2 5.24 0 3.816 5.513 1.682 8.4ZM16.571 59.935c1.243-.456 2.664-.846 4.32-.846 1.776 0 3.256.39 4.558.911v-4.164H29v-8.46h-3.551V22H15.21L0 51.15v4.62h16.571v4.165ZM11 47l6.878-13H18l-.548 6.435V47H11Z" />
      </g>
      <g fill="#FFF">
        <path d="M55.973 10.833H53.31v-4.52L49.799.533v-.4h2.86l1.884 3.59h.13l1.885-3.59h2.925v.4l-3.51 5.782v4.519zM37.386 9.504h-4.094l-.585 1.329h-2.6v-.399L34.721 0h1.17l4.745 10.434v.399h-2.6l-.65-1.33Zm-2.08-5.184-1.17 2.791h2.405L35.306 4.32ZM18.149 5.25c.845.465 1.235 1.263 1.235 2.193 0 1.861-1.105 3.39-3.25 3.39H11.26V.133h4.484a3.24 3.24 0 0 1 3.25 3.256c-.065.665-.325 1.462-.845 1.861Zm-4.354-2.658V4.32h1.69c1.04 0 1.04-1.728 0-1.728h-1.69Zm0 3.987v1.795h2.144c1.105 0 1.04-1.795 0-1.795h-2.144Z" />
      </g>
    </g>
  </svg>
)

export default Bay42LogoGold