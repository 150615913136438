import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
 
const LangSelector = () => {
  const { i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState('serbian');
 
  const changeLanguage = () => {
    const newLanguage = selectedLang === 'serbian' ? 'en' : 'serbian'
    setSelectedLang(newLanguage);
    i18n.changeLanguage(newLanguage);
  }
 
  return (
    <div onClick={changeLanguage}>
      {selectedLang === 'serbian' ? 'English' : 'Srpski'}
    </div>

  )
}
 
export default LangSelector;