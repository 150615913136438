import styled from "styled-components";
import backgroundImage from "../../images/Group10.png";
export const HeroContainer = styled.div`
    // background: #FFC500;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // padding: 0 30px;
    // width: 100%;
    // height: 100vh;
    // // position: relative;
    // z-index: 1;
    // @media(max-width: 576px){
    //     // height: 800px;

    //     height: 100vh;

    // }
    // background: #000; --> THIS || black
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* padding: 0 30px; */
    width: 100%;
    height: 100vh;
    z-index: 1;

   
`

export const HeroBg = styled.div`
    // position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    // background-attachment: fixed;
    background-position: right 0% bottom %;
    background-image: url(${backgroundImage});
    background-position: center;
    // background-color: black;
    // background-position: center;
    // background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    // width: 100vw;
    height: 100%;
    animation: fade-in-scale-down 2s ease-out 1;
    -webkit-animation: fade-in-scale-down 2s ease-in-out 1;
    -moz-animation:    fade-in-scale-down 2s ease-in-out 1;
    -o-animation:      fade-in-scale-down 2s ease-in-out 1;
    @media screen and (max-width: 768px){
        background-position: right;
    }
    @media screen and (max-width: 576px){
        background-attachment: unset;
    }
`