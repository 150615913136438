import styled from "styled-components";
import backgroundImage from "../../images/footer2.png";
export const FooterContainer = styled.div`
    position: relative;
`

export const FooterBg = styled.div`
    width: 100vw;
    height: 800px;
    position: relative;
    width: 100%;
    z-index: 9;
    height: 800px;
    background-size: 100% 100%;
    width: 100vw;
    height: 800px;
    position: relative;
    width: 100%;
    z-index: 9;
    height: 100vh;
    background-image: url(${backgroundImage});
    background-size: cover;
    @media(max-width: 576px) {
        // background-image: none !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        object-fit: contain;
        align-items: center;
        background-position: -175px 0;
        height: 80vh;
    }
    
`