import styled from 'styled-components'
import backgroundImage from '../../images/bg.png'

export const BackgroundMainSection = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-attachment: fixed;
    // background-image: url(${backgroundImage});
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;
    width: 100vw;
    height: 100%;
    position: relative;
    animation: animateBg 50s linear infinite;
`

export const BackgroundWrap = styled.div `
    padding: 100px 50px;
    max-width: 124rem;
    margin: 0 auto;
    position: relative;
    // min-height: 100vh;
    @media(max-width: 576px){
        padding: 50px 25px;
    }
`

