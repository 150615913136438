import styled from "styled-components";
import {FaTimes} from "react-icons/fa"
import { Link as LinkS } from "react-scroll";
import {Link as LinkR } from "react-router-dom"

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100vh;
    padding: 40px;
    background: #52006B;
    display: flex;
    min-height: 100vh;
    align-items: center;
    top:0;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    transition: .3s ease-in-out;
    opacity: ${({isOpen}) => (isOpen ? '100%' : '0')};
    top: ${({ isOpen}) => (isOpen ? '0' : '-100%')}
`
export const CloseIcon = styled(FaTimes) `
    color: #fff;
`
export const Icon = styled.div `
    position: absolute;
    top: 2rem;
    right: 2rem;
    background: transparent;
    font-size: 1rem;
    cursor: pointer;
    outline: none;
    color: white;
    text-transform: none;

    @media(max-width: 576px){
        top: 3.5rem;
    }
`

export const SidebarWrapper = styled.div `
    color: #fff;
`
export const SidebarMenu = styled.ul `
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;

    @media(max-width: 576px) {
        grid-template-rows: repeat(4,70px);
        padding-top: 25px;
        height: 100%;
    }
`
export const SidebarLink = styled(LinkS) `
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 1.5rem;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    &:hover {
        color: #FFC500;
        transition: 0.2s ease-in-out;
    }
    @media(max-width: 576px) {
        font-size: 1.2rem;
    }
`

export const SidebarLinks = styled.a `
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 1.5rem;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    &:hover {
        color: #FFC500;
        transition: 0.2s ease-in-out;
    }
    @media(max-width: 576px) {
        font-size: 1.2rem;
    }
`
export const MobileLink = styled(LinkS) `
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 1.5rem;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    &:hover {
        color: #FFC500;
        transition: 0.2s ease-in-out;
    }
    @media(max-width: 576px) {
        font-size: 1.2rem;
    }
`

