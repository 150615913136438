import React, {useEffect, useState, useRef} from 'react'
import {
    useViewportScroll,
    motion,
    useTransform,
    useMotionValue
  } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import reactStringReplace from 'react-string-replace';


const About = () => {
    const location = useLocation();
    const {t} = useTranslation();
    const content = t('about_us_section1');
    useEffect(()=> {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({behavior: "smooth"})
            }
        } else {
        window.scrollTo({top:0,left:0, behavior: "smooth"})
        }
    }, [location])

    const isMobile = window.innerWidth < 576;
    let variants = {};
    if (!isMobile) {
        variants = {
            visible: { opacity: 1, scale: 1, y: 0 },
            hidden: {
              opacity: 0,
              scale: 0.65,
              y: 0
            }
        };
    }
    const [ref, inView, entry] = useInView({
        threshold: .6,
        triggerOnce: false,
        initialInView: true
    });
    
  return (
      <>
      <div id="about" ></div>
        <motion.div className="text-wrap about-section" id="parallax" animate={inView ? 'visible' : 'hidden'}
            variants={variants}
            transition={{ duration: 1, ease: 'easeOut' }}
            ref={ref}>
            <div>
                <div className="title">
                    <p className="main">
                        {t('something')} <span className="glow">{t('about_section')}</span>{t('Bay42')}
                    </p>
                </div>
                <motion.div
                    animate={inView ? 'visible' : 'hidden'}
                    variants={variants}
                    transition={{ duration: 1, ease: 'easeOut' }}
                    ref={ref}
                    className="columns"
                    >
                    <div>
                        {reactStringReplace(content, '[cb]', (match, i) => (
                            <sup className="superscript">[cb]</sup>
                        ))}
                    </div>
                    <div>
                        {t('about_us_section2')}
                        <div className="space" style={{height:'30px'}}></div> 
                        {t('about_us_section3')}
                    </div>
                </motion.div>
                </div>
        </motion.div>
    </>
  )
}

export default About