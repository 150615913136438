import React, {useState, useEffect} from 'react';
import {Nav, NavbarContainer, NavLogo, LogoLink, MobileIcon, NavLinksTranslate, NavMenu, NavItem, NavLinks, NavIco, NavLinksPage} from './NavbarElements';
import { FaBars } from 'react-icons/fa';
import Modal from '../Modal/Modal';
import ContactIco from '../ContactIco';
import Bay42Logo from '../Bay42Logo';
import PerformcbLogo from '../PerformcbLogo';
import Bay42LogoGold from '../Bay42LogoGold';
import PerformcbWhite from '../PerformcbWhite';
import { useTranslation } from 'react-i18next';
import LangSelector from '../LangSelector';

const Navbar = ( {toggle}, props ) => {
const [isOpened, setIsOpened] = useState(false);
const [navSize, setnavSize] = useState("");
const [linkColor, setLinkColor] = useState("black");
const [scrolled, setScrolled] = useState(false);
const {t} = useTranslation();

const fadeInDown = {
    initial: {
        y:-60,
        opacity: 0,
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.5,
            delay: .2,
            ease: "easeInOut",
        },
    },
};


const listenScrollEvent = () => {
    window.scrollY > 120 ? setnavSize("7rem") : setnavSize("8rem");
    window.scrollY > 120 ? setLinkColor("white") : setLinkColor("black");
    window.scrollY > 120 ? setScrolled(true) : setScrolled(false);

};

useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
        window.removeEventListener("scroll", listenScrollEvent);
    };
}, []);

  return (
   <>
        <Nav 
            style={{
                height: navSize,
                transition: "all 1s"
            }} 
            variants={fadeInDown} initial="initial" animate="animate"
            // className={scrolled ? "shadow" : "transparent"}
            id="home"
        >
            <NavbarContainer>
                <div className="wrap">
                    <a onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})} className={isOpened ? "zIndex jobsPage" : "minus jobsPage"}>
                        {!scrolled ? 
                            <Bay42Logo></Bay42Logo> : <Bay42LogoGold></Bay42LogoGold>
                        }
                    </a>
                    <MobileIcon onClick={toggle}>
                        {!scrolled ? 
                            <img src={require('../../images/menu icon.png')} /> :
                            <img src={require('../../images/menu icon yellow.png')} />
                        }
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks style={{
                            color: linkColor,
                            transition: "all 1s"}}
                            to="jobs">{t('jobs')}</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks style={{
                            color: linkColor,
                            transition: "all 1s"}}
                            to="about">{t('about')}</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks style={{
                                color: linkColor,
                                transition: "all 1s"}}
                                onClick={() => setIsOpened(true)}>
                                    {t('contact')}
                            </NavLinks>
                            <Modal isOpened={isOpened} setIsOpened={setIsOpened} />
                        </NavItem>
                        <NavItem>
                            <NavLinksTranslate style={{
                                color: linkColor,
                                transition: "all 1s"}}  
                                >
                                <LangSelector />
                            </NavLinksTranslate>
                        </NavItem>
                    </NavMenu>
                </div>
                <LogoLink href="https://www.performcb.com" target="_blank" rel="noopener noreferrer" className={isOpened ? "zIndex mobile-hide" : "minus mobile-hide"}>
                    {scrolled ? 
                        <a><PerformcbWhite></PerformcbWhite></a> :
                        <PerformcbLogo></PerformcbLogo>
                    }
                </LogoLink>
            </NavbarContainer>
        </Nav>
   </>
  )
}

export default Navbar