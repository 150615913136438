import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import '../../src/form.css';
import ContactIco from './ContactIco';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const FormSignup = () => {
  const form = useRef();
  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup.string().required(t('field_empty')).email(t('mail_not_valid')),
    message: yup.string().required(t('field_empty')),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const [result, setResult] = useState('');

  const renderAlert = () => (
    <div className="form-success-message">
      <span>{t('success_message')}</span>
    </div>
  );

  const sendEmail = async (data) => {
    try {
      await axios.post('http://localhost:3000/send-email', data);
      setResult('SUCCESS');
    } catch (error) {
      console.error('Error sending email:', error);
      setResult('ERROR');
    }
  };

  const onSubmit = (data) => {
    sendEmail(data);
    // Clear form fields after submission
    form.current.reset();
  };

  return (
    <>
      <div className='form-content-right'>
        {result === 'SUCCESS' && renderAlert()}
        <form ref={form} onSubmit={handleSubmit(onSubmit)} className='form' noValidate autoComplete="off">
          <div className='form-inputs'>
            <label className='form-label'>{t('email_address')}</label>
            <input
              className='form-input'
              type='email'
              name='email'
              {...register('email', { required: true })}
            />
            <p className="form-errors">{errors.email && errors.email.message}</p>
          </div>
          <div className='form-inputs'>
            <label className='form-label'>{t('message')}</label>
            <input
              className='form-input'
              type='text'
              name='message'
              {...register('message', { required: true })}
            />
            <p className="form-errors">{errors.message && errors.message.message}</p>
          </div>
          <div className="form-button-anim">
            <button type="submit" className="btn-form">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <ContactIco />
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default FormSignup;

