import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'
import { Link as LinkP } from 'react-router-dom'

import {FaBars} from 'react-icons/fa'
import {motion} from "framer-motion"


export const Nav = styled(motion.nav)`
    background: transparent;
    padding-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: fixed;
    width: 100%;
    // top: 0;
    z-index: 10;
    transition: all 1s;
    &.shadow {
        background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.8));
        transition: all 1s;
    }
    &.transparent {
        background: transparent;
        transition: all 1s
    }
    @media screen and (max-width: 960px) {
        transition: 0.8 all ease;
    }
`

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    z-index: 1;
    width: 100%;
    padding: 0 50px;
    max-width: 124rem;
    margin: 0 auto;
    @media(max-width: 576px){
        padding: 0 20px;
    }
    .wrap {
        display: flex;
        align-items: center;
        @media(max-width: 576px){
            justify-content: space-between;
            max-width: 100%;
            width: 100%;
        }
    }
`

export const NavLogo = styled(LinkR) `
    transform: scale(.8);
    @media(max-width: 576px){ 
        transform: scale(1);
    }
    &.zIndex {
        z-index: -1;
    }
    &.minus {
        z-index:999;
    }
    &.mobile-hide {
        @media(max-width: 576px){ 
            display: none;
        }
    }
` 
export const LogoLink = styled.a `
    transform: scale(.8);
    @media(max-width: 576px){ 
        transform: scale(1);
    }
    &.zIndex {
        z-index: -1;
    }
    &.minus {
        z-index:999;
    }
    &.mobile-hide {
        @media(max-width: 576px){ 
            display: none;
        }
    }
` 

export const MobileIcon = styled.div`
    display: none;
    @media screen and (max-width: 768px){
        display: flex;
        cursor: pointer;
        padding: 1rem;
        img {
            width: 35px;
            height: 35px;
            margin: 1rem;
            margin-right:0;
        }
    }
`

export const NavMenu = styled(motion.ul)`
    display: flex; 
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`
export const NavItem = styled.li`
    height: 80px;
`

export const NavLinks = styled(LinkS) `
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    color: white;
    &.active {
        border-bottom: 3px solid #01bf71;
    }
` 
export const NavLinksTranslate = styled.div `
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    color: white;
    &.active {
        border-bottom: 3px solid #01bf71;
    }
` 
export const NavLinksPage = styled(LinkP) `
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    color: white;
    &.active {
        border-bottom: 3px solid #01bf71;
    }
` 

export const NavIco = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    @media(max-width: 576px){ 
        display: none;
    }
`