
import './App.css';
import React, {useState, useEffect, useRef} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Home from './pages';
import CustomCursor from './components/Cursor'
import Jobs from './components/Jobs/index'
import Navbar from './components/Navbar'
import Sidebar from './components/Navbar/Sidebar'
import { AnimatePresence } from 'framer-motion';
import Loader from './components/Loader';
import useScrollSnap from "react-use-scroll-snap";
import ScrollContainer from './components/ScrollContainer';
import ParallaxElement from "./components/ParallaxElement";

function App() {

  const box1 = useRef(null);
  const box2 = useRef(null);
  const box3 = useRef(null);
  const box4 = useRef(null);
  // const scrollRef = useRef(null);
  // useScrollSnap({ ref: scrollRef, duration: 0, delay: 0 });
  const [isOpen, setIsOpen]= useState(false);
  useEffect(()=> {
    setLoading(true)
    setTimeout(() => {
        setLoading(false);
    }, 2500);
}, [])
  const toggle = () => {
      setIsOpen(!isOpen);
  }
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModal] = useState(false);

  const openModal = event => {
    event.preventDefault();
    const {
      target: {
        dataset: { modal }
      }
    } = event;
    if (modal) 
      setModal(modal);
  };

  const closeModal = () => {
    setModal('');
  };
  return (
    <>
      <CustomCursor />
      <AnimatePresence exitBeforeEnter >
        <Router>
          {loading ? 
            (
              <Loader loading={loading} />
            ) : (
            <> 
            <div onClick={openModal} >
              <Sidebar isOpen={isOpen} toggle={toggle} />
              <Navbar toggle={toggle} />
              <ScrollContainer>
                <div> 
                    <Home />
                    <Jobs closeFn={closeModal} modal={modalOpen} />
                </div>
              </ScrollContainer>
            </div>
            </>
            )
          }
          </Router>
      </AnimatePresence>
    </>
  );
}

export default App;
