import {React, FC, useState, useEffect, useRef}  from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from 'react-i18next';
import Form from '../Form';
const backdropVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: .5,
        delayChildren: 0,
      },
    },
};
  
const modalVariant = {
    hidden: {
        x: "100vh",
    },
    visible: {
        x: 0,
        transition: {
        type: "spring",
        stiffness: 70,
        },
    },
};


const Modal = ({isOpened, setIsOpened}) => {
  
  const ref = useRef();
  const {t} = useTranslation();
  useOnClickOutside(ref, () => setIsOpened(false));

return (
  <div>
    <AnimatePresence>
      
      {/* <Cursor /> */}
      {isOpened ? (
        <motion.div
          ref={ref}
          className="modal-backdrop"
          variants={backdropVariant}
          initial="hidden"
          animate="visible"
          exit="hidden"
          
        >
          <motion.div className="modal-container" variants={modalVariant}>
            
            <motion.div >
              <div className="wrapper">
                <div className="modal-header">{t('how_can_we')}<span className="glow-no-hover">{t('help')}</span>{t('you')}</div>
                <div>
                  <p>
                    {t('contact_modal_messsage')}
                  </p>
                </div>
                <motion.div
                  whileHover={{ rotate: 45 }}
                  className="close-modal"
                  onClick={() => setIsOpened(false)}>
                </motion.div>
                <Form  />
              </div>
              </motion.div>
          </motion.div>
        </motion.div>
        ) : (
        null
      )}
      {/* )} */}
      </AnimatePresence>
    </div>
  );
}

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}
export default Modal;