import React, {useState} from 'react'
import HeroSection from '../components/HeroSection';
import CustomCursor from '../components/Cursor'
import Transitions from '../components/Transitions';
const Home = () => {
    return (
        <>
         {/* <Transitions> */}
            <HeroSection />
        {/* </Transitions> */}
        </>
    )
}

export default Home