import * as React from "react"

const ContactIco = (props) => (
  <svg width={21} height={17} xmlns="http://www.w3.org/2000/svg" {...props} className="modal-nav form-icon">
    <title>{"Shape"}</title>
    <path
      d="M0 0v16.059h21V0H0Zm10.493 9.656-8.519-8.42h17.072l-8.553 8.42ZM7.109 8.05l-5.874 5.784V2.243L7.11 8.048Zm.878.869 2.503 2.474 2.466-2.429 5.93 5.86H1.99l5.997-5.905Zm5.85-.82 5.928-5.837v11.694l-5.928-5.858Z"
      fill="#52006B"
      fillRule="nonzero"
    />
  </svg>
)

export default ContactIco
