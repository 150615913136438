import * as React from "react"

const LinkedinIco = (props) => (
  <svg width={19} height={19} xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{"linkedIn"}</title>
    <path
      d="M0 6.333h4.524V19H0V6.333ZM2.274 0C.904 0 0 .979 0 2.263c0 1.255.868 2.26 2.225 2.26h.025c1.405 0 2.285-1.005 2.274-2.26C4.51.979 3.655 0 2.274 0Zm11.95 5.429c-2.214 0-3.199 1.287-3.744 2.195V5.737H6.333s.051 1.245 0 13.263h4.147v-7.402c0-.4.038-.79.138-1.083.303-.79.982-1.606 2.132-1.606 1.497 0 2.102 1.218 2.102 2.998V19H19v-7.602c0-4.07-2.042-5.97-4.777-5.97Z"
      fill="#FFF"
      fillRule="nonzero"
    />
  </svg>
)

export default LinkedinIco
