import React from 'react'
import FacebookIco from './Facebook'
import InstagramIco from './InstagramIco'
import LinkedinIco from './Linkedin'
const SocialMedia = () => {
  const urlFb = 'https://www.facebook.com/bay42.io/';
  const urlIg = 'https://www.instagram.com/bay42_banjaluka/';
  const urlLn = 'https://ba.linkedin.com/company/bay42-banja-luka';
  return (
    <div className="social-media-wrap">
      <div onClick={() => window.open(urlFb, '_blank')} target="_blank" rel="noopener noreferrer">
        <FacebookIco></FacebookIco>
      </div>
      <div onClick={() => window.open(urlIg, '_blank')} target="_blank" rel="noopener noreferrer">
        <InstagramIco></InstagramIco>
      </div>
      <div onClick={() => window.open(urlLn, '_blank')} target="_blank" rel="noopener noreferrer">
        <LinkedinIco></LinkedinIco>
      </div>
    </div>
  )
}

export default SocialMedia