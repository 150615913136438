import * as React from "react"

const InstagramIco = (props) => (
  <svg width={19} height={19} xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{"Instagram"}</title>
    <path
      d="M17.1 0H1.9C.855 0 0 .855 0 1.9v15.2C0 18.145.855 19 1.9 19h15.2c1.045 0 1.9-.855 1.9-1.9V1.9C19 .855 18.145 0 17.1 0ZM9.5 5.7c2.09 0 3.8 1.71 3.8 3.8s-1.71 3.8-3.8 3.8-3.8-1.71-3.8-3.8 1.71-3.8 3.8-3.8ZM2.375 17.1c-.285 0-.475-.19-.475-.475V8.55h1.995c-.095.285-.095.665-.095.95 0 3.135 2.565 5.7 5.7 5.7 3.135 0 5.7-2.565 5.7-5.7 0-.285 0-.665-.095-.95H17.1v8.075c0 .285-.19.475-.475.475H2.375ZM17.1 4.275c0 .285-.19.475-.475.475h-1.9c-.285 0-.475-.19-.475-.475v-1.9c0-.285.19-.475.475-.475h1.9c.285 0 .475.19.475.475v1.9Z"
      fill="#FFF"
      fillRule="nonzero"
    />
  </svg>
)

export default InstagramIco
