import React, {useRef} from "react";
import About from "./About/About";
import ParallaxElement from "./ParallaxElement";

export default function Para() {
  const box2 = useRef(null);
  return (
    <>
      <About />
      <ParallaxElement dataPercent="35" dataDirection="y">
        <div ref={box2} className="box box-right">
          <img src={require('../images/planetica.png')} />
        </div>
      </ParallaxElement>
    </>
  );
}
