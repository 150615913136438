import React,  {useState, useEffect, useRef} from 'react'
import Navbar from '../Navbar'
import { HeroContainer, HeroBg } from './HeroElements'
import Sidebar from '../Navbar/Sidebar'
import {
    useViewportScroll,
    motion,
    useTransform,
    useMotionValue, AnimatePresence
} from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

const parallaxData = [
    {
      start: 0,
      end: 500,
      properties: [
        {
          startValue: 1,
          endValue: 2,
          property: 'scale',
        },
      ],
    },
  ];

const HeroSection = () => {
    const parallaxDiv = React.useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (parallaxDiv.current) {
                // parallaxDiv.current.style.backgroundPositionY = `${window.pageYOffset * 2}px`;
                parallaxDiv.current.style.opacity = `${1 - +window.pageYOffset / 600}`;
                parallaxDiv.current.style.top = `${+window.pageYOffset + 300}%`;
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);



    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    useEffect(()=>{
        setLoading(true)
        setTimeout(() => {
            setLoading(false);
        }, 2500);
    }, [])
    const variants = {
        visible: { opacity: 1, scale: 1, y: 0 },
        hidden: {
          opacity: 0,
          scale: 0.65,
          y: 50
        }
    };
    const { scrollY } = useViewportScroll();
    const y1 = useTransform(scrollY, [0, 300], [0, 200]);
    const y2 = useTransform(scrollY, [0, 300], [0, -100]);

    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 1,
        triggerOnce: false
    });
    const [isOpen, setIsOpen]= useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    }
    const fadeInDown = {
        initial: {
            y:-60,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.5,
                delay: 1.5,
                ease: "easeInOut",
            },
        },
    };
    const fadeInUp = {
        initial: {
            x:0,
            opacity: 0,
        },
        animate: {
            x: 15,
            opacity: 1,
            transition: {
                duration: 1,
                delay: 0.5,
                ease: "easeInOut",
            },
        },
    };
    const fadeIn = {
        initial: {
            x:140,
            opacity: 0,
        },
        animate: {
            x: 70,
            opacity: 1,
            transition: {
                duration: 1,
                delay: 0.5,
                ease: "easeInOut",
            },
        },
    };
    
    return (
        <>
            <HeroContainer>
                <HeroBg ref={parallaxDiv}>
                </HeroBg>
            </HeroContainer>
        </>
    )
}

export default HeroSection;