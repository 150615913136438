import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const modalRootEl = document.getElementById('modal-root');

const Modal = ({ children, open = false }) => {
  if (!open)
    open = false

  return ReactDOM.createPortal(children, modalRootEl);
};

export default Modal;
