import React, {useState, useEffect} from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLinks, SidebarLink, MobileLink } from './SidebarElements'
import { NavLogo } from '../NavbarElements'
import Bay42LogoGold from '../../Bay42LogoGold'
import Modal from '../../Modal/Modal';
import SocialMedia from '../../SocialMedia/SocialMedia';
import PerformcbWhite from '../../PerformcbWhite'
import { useTranslation } from 'react-i18next';
import LangSelector from '../../LangSelector';

const Sidebar = ({isOpen, toggle}) => {
    const [isOpened, setIsOpened] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if(isOpen) {
            document.body.classList.add('fixed-body');
        }else {
            document.body.classList.remove('fixed-body');
        } 
    }, [isOpen]);

  return (
    <>
        <SidebarContainer isOpen={isOpen}>
            <NavLogo to="/">
                <Bay42LogoGold></Bay42LogoGold>
            </NavLogo>
            <Icon onClick={toggle}>
                {t('close')}
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <MobileLink to="jobs" onClick={toggle}>
                        {t('jobs')}
                    </MobileLink>
                    <MobileLink to="about" onClick={toggle}>
                        {t('about')}
                    </MobileLink>
                    <SidebarLink href="contact" onClick={() => setIsOpened(true)}>
                        {t('contact')}
                    </SidebarLink>
                    <SidebarLinks onClick={toggle}>
                        <LangSelector />
                    </SidebarLinks>
                </SidebarMenu>
            </SidebarWrapper>
            <Modal isOpened={isOpened} setIsOpened={setIsOpened} />
            <div className="sidebar-footer">
                <SocialMedia />
                <div onClick={() => window.open('https://www.performcb.com')}>
                    <PerformcbWhite />
                </div>
            </div>
        </SidebarContainer>
        
    </>
  )
}
 
export default Sidebar