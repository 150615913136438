import React from "react";
import { motion } from "framer-motion";
const style = {
    width: 15,
    height: 15,
    opacity: 1,
    margin: 5,
    borderRadius: 0,
    display: "inline-block",
    background: "#c81c60",
}
  
const variants = {
    start: {
        scale: 0.2,
        rotate: 0,
    },
    end: {
        scale: 1,
        rotate: 360,
    },
}

export default function Loader(props) {
    return (
        <div className="loader fadeIn" style={{flexDirection:'column'}}>
        <div style={{display:'flex'}}>
          <motion.div
            style={style}
            variants={variants}
            initial={"start"}
            animate={"end"}
            transition={{    
                repeat: "Infinity",
                repeatType: "reverse",
                ease: "anticipate",
                duration: 1, 
                delay: 0
            }}
            className="dash uno"
        />
          <motion.div
            style={style}
            variants={variants}
            initial={"start"}
            animate={"end"}
            transition={{    
                repeat: "Infinity",
                repeatType: "reverse",
                ease: "anticipate",
                duration: 1, 
                delay: 0.2,
                
            }}
            className="dash dos"
        />
          <motion.div
            style={style}
            variants={variants}
            initial={"start"}
            animate={"end"}
            transition={{    
                repeat: "Infinity",
                repeatType: "reverse",
                ease: "anticipate",
                duration: 1, 
                delay: 0.4
            }}
            className="dash tres"
        />
          <motion.div
            style={style}
            variants={variants}
            initial={"start"}
            animate={"end"}
            transition={{    
                repeat: "Infinity",
                repeatType: "reverse",
                ease: "anticipate",
                duration: 1, 
                delay: 0.6
            }}
            className="dash cuatro"
        />
        </div>
        </div>
    )
}