import React, {useState, useEffect, useRef} from 'react';
import ArrowIcon from '../ArrowIcon'
import { BackgroundMainSection, BackgroundWrap } from '../BgWrap/BgWrapElements'
import {motion, useAnimation} from "framer-motion";
import JobModalPHP from "../JobPostings/JobModalPHP"
import JobModalPHPSenior from "../JobPostings/JobModalPHPSenior"
import JobModalPHPIntern from "../JobPostings/JobModalPHPIntern"
import Para from "../Para"
import {Slide } from "react-awesome-reveal";
import Footer from "../Footer/index"
import { useTranslation } from 'react-i18next';
import ParallaxElement from '../ParallaxElement';
import { useInView } from 'react-intersection-observer';
import JobModalReactJsJunior from "../JobPostings/JobModalReactJsJunior";

import { FadeIn } from 'react-slide-fade-in';

const Jobs = ({closeFn, modal = '' }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [overflow, setOverflow] = useState("hidden");
  const { t } = useTranslation();
  const box1 = useRef(null);
  const box2 = useRef(null);


  useEffect(() => {
    if(modal != '') {
        document.body.classList.add('fixed-body');
    }else {
        document.body.classList.remove('fixed-body');
    } 
  }, [modal]);

  useEffect(() => {
    window.onscroll = function() {
      if (window.scrollY > 60) {
        setOverflow('initial');
      } else {
        setOverflow('hidden');
      }
    };
}, []);
  
  //job modal fixich
  function parallax() {
    var s = document.getElementById("plan");
    var yPos = 0 - window.pageYOffset/10;  
    s.style.top = 73 + yPos + "vh"; }

  window.addEventListener("scroll", function(){
    parallax(); 
  });

  function handleMouseEnter() {
    setIsHovered(true);
  }

  function handleMouseLeave() {
    setIsHovered(false);
  }
  const [ref, inView, entry] = useInView({
    threshold: .8,
    triggerOnce: false
  });
  
  const variants = {
    visible: { opacity: 1, scale: .8, y: 0 },
    hidden: {
      opacity: 0,
      scale: 0.65,
      y: 150
    }
  };
  return (
    <BackgroundMainSection id="jobs" style={{overflow: overflow }}>
      <span className="falling"></span>
      <span className="falling"></span>
      <motion.div
        className="App">
        <motion.img
          className="confetti"
          id="conf"
          alt="confeti background"
          src={require('../../images/confeti2.png')}
          animate={inView ? 'visible' : 'hidden'}
          variants={variants}
          transition={{ duration: 2, ease: 'easeOut' }}
          ref={ref}
          >
        </motion.img>
        <motion.img
          alt="planets background"
          className="planets" 
          id="plan"
          src={require('../../images/planete delfin i saksija2.png')}>
        </motion.img>
        <motion.div className="imageWoman">
          <img src={require('../../images/zenaUziva.png')}
          id="zemska" />
        </motion.div>
      </motion.div>
      <div className="fixxx" style={{minHeight:'100vh'}}>
      <BackgroundWrap>
        <FadeIn 
          from="bottom"
          positionOffset={250}
          triggerOffset={100}
          delayInMilliseconds={0}
        >
          <div className = "title jobs-section">
            <p className="main jobs-main">
              {t('jobs_title')}
              <span className="glow">{t('super')}</span>{t('people')}
            </p>
          </div>
        </FadeIn>
        <div>
            {/*<div className={mobileNavOpen ? "list-wrap navHidden" : "list-wrap navVisible"}> */}
            {/*  <div className="items" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>*/}
            {/*    <div className="one">*/}
            {/*      <Slide delay={300}>*/}
            {/*        <motion.div className="items-wrap">*/}
            {/*          <p data-modal="job-two">Senior PHP Developer</p>*/}
            {/*              <ArrowIcon />*/}
            {/*        </motion.div>*/}
            {/*      </Slide>*/}
            {/*      <JobModalPHPSenior closeFn={closeFn} open={modal === 'job-two'} />*/}
            {/*    </div>*/}
            {/*    <div className="one">*/}
            {/*      <Slide delay={200}>*/}
            {/*        <motion.div className="items-wrap" >*/}
            {/*          <p data-modal="job-one">Junior React Developer</p>*/}
            {/*              <ArrowIcon />*/}
            {/*        </motion.div>*/}
            {/*      </Slide>*/}
            {/*      <JobModalReactJsJunior closeFn={closeFn} open={modal === 'job-one'} />*/}
            {/*    </div>*/}
            {/*    */}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <Para />
          <div className="glowing_stars">
            {Array.apply(null, { length: 12 }).map((e, i) => (
              <div className="star" key={i}></div>))
            }
          </div>
          <ParallaxElement dataPercent="25" dataDirection="x">
            <div ref={box1} className="box box-star">
                <img src={require('../../images/star.png')} />
            </div>
          </ParallaxElement>
      </BackgroundWrap>
      </div>
      <Footer />
    </BackgroundMainSection>
  );
}

export default Jobs;