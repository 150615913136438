import * as React from "react"

const FacebookIco = (props) => (
  <svg width={19} height={19} xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{"Facebook"}</title>
    <path
      d="M15.833 0H3.167C1.502 0 0 1.503 0 3.167v12.666C0 17.497 1.502 19 3.167 19H9.5v-7.178H7.178V8.656H9.5V7.422c0-2.128 1.598-4.044 3.563-4.044h2.559v3.167h-2.56c-.28 0-.607.34-.607.85v1.26h3.167v3.167h-3.167V19h3.378C17.498 19 19 17.497 19 15.833V3.167C19 1.503 17.498 0 15.833 0Z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
)

export default FacebookIco